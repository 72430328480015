
$moduleNameSpace: dynamic-name-space;
@keyframes fadeIn {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

[data-sparta-container] .spa-sticky {
  &.fade-in {
    animation: fadeIn 0.5s ease forwards;
  }

  &.active {
    position: fixed;
    z-index: 50;

    + .sticky-placeholder {
      display: block;
    }
  }


  &[data-sticky-fade='true'] {
    opacity: 0;
  }


  + .sticky-placeholder-fade,
  &[data-no-placeholder='true'] + .sticky-placeholder {
    height: 0 !important;
    overflow: hidden;
  }
}
